import React from 'react'

import { PageLayout } from '@/components/PageLayout'
import { Head } from '@/components/head'
import { newsList } from '@/constants/news'
import { NewsList } from '@/components/common/news'

export default function News(): JSX.Element {
  return (
    <>
      <Head title="お知らせ| 株式会社Relicホールディングス" url="news" />
      <PageLayout
        title="お知らせ"
        subTitle="NEWS"
        breadcrumbs="/NEWS"
        breadcrumbsPath="/news"
      >
        <NewsList newsList={newsList} isPage />
      </PageLayout>
    </>
  )
}
